.resume {
    display: flex;
    flex-direction: column;
}

.resume ul {
    list-style: none;
}

.resume span {
    font-size: 1.875rem;
}

.resume h2 {
    font-size: 1.5rem;
}