.page {
    color: white;
    padding: 6rem;
    padding-left: 2rem;
    margin-left: 144.037px;
    margin-right: 144.037px;

}

.swiperslide {
    display: flex;
    margin-left: 144.037px;
    padding: 2rem;
    color: white;
    align-content: center;
    margin-right: 144.037px;
}

@media(max-width:1109px) {
    .page {
        margin: 0;
        padding: 1rem;
        margin-left: 144.037px;

    }

    .swiperslide {
        margin: 0;
        padding: 1rem;
        margin-left: 144.037px;
    }
}

@media(max-width:767px) {
    .page {

        margin-left: 0;

    }

    .swiperslide {

        margin-left: 0;
    }
}