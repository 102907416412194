.logo {
    height: 80px;
    filter: grayscale(90%);
}

h1 {
    text-justify: center;
    font-size: 1.875rem;
}

.block {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.block h1 {
    justify-content: center;
    padding: 2rem 0;
    margin: 0;

}

.profileContainer {
    height: 100px;
    text-align: left;
    display: flex;
    flex-direction: row;
    gap: 4rem;
    padding-bottom: 2rem;
    margin-top: 1rem;

}

.profile {
    height: 100px;
    border-radius: 360px;
    filter: grayscale(70%);
    user-select: none;

}

aside {
    display: flex;
    flex-direction: column;
    background-color: black;
    gap: 0.1rem;
    font-size: 150%;
    color: rgb(214, 213, 213)
}

.profileContainer a {
    color: rgb(214, 213, 213)
}

span:hover {
    cursor: pointer;
}

p {
    margin-top: 1rem;
}