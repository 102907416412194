.project_container {
    display: flex;
    flex-direction: column;

}

.container p {
    padding: 0.05rem;

}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.container img {
    height: 300px;
    max-width: 518px;
    min-width: 200px;
    cursor: pointer;
    filter: grayscale(70%);

}

.container img:hover {
    height: 310px;
    max-width: 528px;

}

.container a {
    font-size: 1.875rem;
    text-decoration: none;
    color: white;
    margin: 1rem;
}

.swiper {
    --swiper-theme-color: #606060;
}

@media(max-width:1109px) {

    .container img {
        height: 200px;
        width: 300px;
        cursor: pointer;
        filter: grayscale(70%);

    }

    .container img:hover {
        height: 310px;
        max-width: 528px;

    }
}