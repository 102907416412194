.contactContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}


input[type="text"] {
    border: none;
    border: 1px solid #606060;
    border-radius: 0.5rem;
    color: #fff;
    margin-bottom: 1rem;
    height: 3rem;
    width: 50%;
}

textarea {
    border: 1px solid #606060;
    color: white;
    border-radius: 0.5em;
    height: 8rem;
    resize: none;
    width: 50%;
    margin-bottom: 1.5rem;

}

input[type="submit"] {
    border: 1px solid white;
    color: white;
    height: 3rem;
    border-radius: 0.5em;
    width: 8rem;
    transition: all ease-in 0.2s;

}

input[type="submit"]:hover {
    color: black;
    background-color: white;
    cursor: pointer;
}

@media(max-width:1400px) {
    input[type="text"] {

        width: 80%;
    }

    textarea {

        width: 80%;


    }
}