@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaisei+HarunoUmi:wght@700&display=swap');

* {
  margin: 0;
  background-color: #111010;
  font-family: 'Kaisei HarunoUmi', serif;
  line-height: 1.6;

}

@media(max-width:711px) {
  * {
    padding: 0.2rem;
  }
}