.header {
    list-style-type: none;
    padding: 0;
    margin-top: 100px;

}

.nav {
    position: fixed;
    height: 100%;
    margin: 0;
    padding: 1rem 1rem;
    overflow: auto;

}

.nav li {
    padding: 0.2rem;

}

.link {
    text-decoration: none;
    color: rgb(178, 176, 176);
    padding: 0.5rem;
    border-radius: 1rem;
}

.active,
.link:hover {
    text-decoration: none;
    background-color: rgba(240, 248, 255, 0.074);
    color: white;

}

ul li {
    font-size: larger;
    font-weight: bold;

    margin: 0.5rem;
    text-align: center;
    font-size: 16px;

}

.initial {
    font-size: 3rem;
    color: white;
}

@media(max-width:767px) {
    .nav {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0;
        padding: 1rem 1rem;
        overflow: auto;

    }

    .nav ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding: 0;
    }


}